<template>
  <!-- <div :class="isWX?'homewx':'homeapp'">
    <HeaderBar title="浣熊出行" :showLeft="false" v-if="!isWX"></HeaderBar>
  <nav-bar :navItems="navItems" :activeItemId="activeId" @tab="tabNav" @popUserInfo="popUserInfo"></nav-bar>-->

  <div :class="isWX ? 'homewx' : 'homeapp'">
    <HeaderBar
      title="浣熊出行"
      :showLeft="false"
      v-if="!isWX"
    ></HeaderBar>
    <nav-bar
      :navItems="navItems"
      :activeItemId="activeId"
      @tab="tabNav"
      @popUserInfo="popUserInfo"
    ></nav-bar>
    <!-- <raccoonContainer :nearBy="nearBy"
    :orderItem="orderItem">-->
    <div
      class="nearBy"
      v-if="orderItem.length === 0"
    >
      附近有{{
        startAddress == ""
          ? "..."
          : tripCarList == []
          ? 0
          : tripCarList
          ? tripCarList.length
          : "..."
      }}位司机候驾
    </div>
    <div
      class="hasOrder"
      v-if="orderItem.length > 0"
      @click="goToOrder"
    >
      <span>您有订单正在进行</span>
      <i class="order-icon"></i>
    </div>
    <!--  -->
    <div class="tripLine">
      <!-- <enthesis :trip="trip"></enthesis> -->
      <div class="enthesis">
        <!-- start address -->
        <div class="start-container">
          <div
            class="start-input"
            @click.stop.prevent="selectAddress('designated', '1')"
          >
            <input
              type="text"
              :placeholder="startPlaceholder"
              class="raccoon-input"
              readonly
              v-model="startAddress"
            />
            <!-- <img
              src="../../assets/iconloca.png"
              alt=""
              srcset=""
              @click.stop.prevent="getgoogleMapPosition"
              class="posticon"
            > -->
          </div>
        </div>
 <div class="divide-line"></div>
        <!-- transfer address -->
        <div
          class="trans-container"
          v-for="(item, index) in transferLocation"
          :key="index"
        >
          <div
            class="trans-input"
            @click.stop.prevent="selectAddress('designated', '3', index)"
          >
            <input
              type="text"
              :placeholder="transferPlaceholder"
              class="raccoon-input"
              readonly
              v-model="transferLocation[index].city"
            />
          </div>
          <div
            class="del-transfer"
            @click.stop.prevent="deleteItem(index)"
          ></div>
        </div>

        <!-- end address -->
        <div class="end-container">
          <div class="end-input">
            <input
              type="text"
              :placeholder="endPlaceholder"
              class="raccoon-input"
              readonly
              v-model="endAddress"
              autocomplete="off"
              @click.stop.prevent="selectAddress('designated', '2')"
            />

          </div>
          <div
            class="add-transfer"
            @click.stop.prevent="addTransferLocation"
            v-if="trip"
          ></div>
        </div>
      </div>
    </div>

    <div
      :class="[
        'startoff',
        this.activeItemId == 0 ? 'startoff-box' : 'startoff-appointment',
      ]"
      v-if="readyGo"
    >
      <div class="tabs">
        <div
          class="items"
          ref="itemList"
        >
          <div
            class="item-list"
            v-for="(item, index) in items"
            :key="index"
            @click.stop.prevent="tab(index)"
            :class="{ active: index == activeItemId }"
          >
            {{ item }}
          </div>
        </div>
      </div>
	  <div class="box-line"></div>
      <div class="trip-items">
        <div
          class="passengerNum items-list"
          @click.stop.prevent="popAppointTime"
          v-if="this.activeItemId == 1"
        >
          <div
            class="appointment-time"
            v-if="appointmentTime == ''"
          >
            <div class="clock"></div>
            <span>出发时间</span>
          </div>
          <div
            class="appointment-time"
            v-if="appointmentTime != ''"
          >
            {{ appointmentTime }}
          </div>
        </div>
        <!-- <div class="passengerNum items-list"
             @click.stop.prevent="popPeopleNumber">
          <div class="items-title">
            人数
          </div>
          <div class="passenger-num">
            <div>{{peopleNum}}人</div>
          </div>
          <div class="right-arrow"></div>
        </div>-->
        <!-- 车辆列表 -->
        <div
          class="passengerNum items-list siji"
          v-if="this.activeItemId == 0"
        >
          <div class="items-title">司机</div>
          <div
            class="car-type"
            @click.stop.prevent="
              $router.push(`/driverList/${typeList[1].typenumber}`)
            "
            v-if="defaultCar"
          >
            <div class="carIcon">
              <img
                :src="imgUrl"
                alt
              />
            </div>
            <div class="car-tips">
              <div class="car-name">{{ defaultCar.PartnerName }}</div>
              <div class="car-des">
                <div class="car-star">
                  <i class="car-star-icon"></i>
                  <span>5.0</span>
                </div>
                 
				<div class="car-distance">
				  <span>{{
				    `${(defaultCar.Distance / 1000).toFixed(1)}km`
				  }}</span>
				</div>
              </div>
            </div>
          </div>
          <div
            class="no-car"
            @click.stop.prevent="
              $router.push(`/driverList/${typeList[1].typenumber}`)
            "
            v-if="!defaultCar"
          >
            <i class="raccoon-err"></i>附近无司机,请预约下单
          </div>
          <div class="right-arrow"></div>
        </div>
        <!-- 儿童座椅 -->
        <!-- <div class="passengerNum items-list"
             @click.stop.prevent="popSelectSeat"
             v-if="this.activeItemId==1">
          <div class="items-title">
            儿童座椅
          </div>
          <div class="passenger-num">
            <div>{{childseat==0?'无儿童座椅':'有儿童座椅'}}</div>
          </div>
          <div class="right-arrow"></div>
        </div>-->

        <div class="passengerNum items-list">
          <div class="items-title">电话</div>
          <div
            class="preNum"
            @click.stop.prevent="popupPhonePre"
          >
            <span>{{ telPre }}</span>
            <i class="selectCountry"></i>
          </div>
          <div
            class="passenger-num"
            @click.stop.prevent="popModifyPhone"
          >
            {{ vehicleUserTel }}
          </div>
          <div class="right-arrow"></div>
        </div>

        <div
          class="passengerNum items-list"
          @click.stop.prevent="popCouponList"
        >
          <div class="items-title">优惠券</div>
          <div
            class="select-coupon"
            v-if="selectedcoupon == ''"
          >
            请选择优惠券
          </div>
          <div
            class="selected-coupon"
            v-if="selectedcoupon !== ''"
          >
            选择一张{{
              selectedcoupon.HDJType == 1
                ? `$${selectedcoupon.Maxmoney}`
                : `${selectedcoupon.Percents}折`
            }}优惠券
          </div>
          <div class="right-arrow"></div>
        </div>
        <div
          class="passengerNum items-list"
          @click.stop.prevent="popTips"
          v-if="this.activeItemId == 1"
        >
          <div class="items-title">小费</div>
          <div
            class="select-coupon"
            v-if="userTips == ''"
          >$0.00</div>
          <div
            class="selected-coupon"
            v-if="userTips != ''"
          >
            ${{ parseFloat(userTips) }}
          </div>
          <div class="right-arrow"></div>
        </div>
		      <!-- 
        <div class="sum">
          <div class="price">
            <span class="cny"></span>
            
            <span
              class="dollar"
              v-if="selectedcoupon !== ''"
            >${{ orderPay }}</span>
            <span
              class="dollar"
              v-if="selectedcoupon == ''"
            >${{ orderPay2 }}</span>
            <span class="cny">(¥{{ selectedcoupon!== ''?Math.floor(orderPay * rateNum):Math.floor(orderPay2 * rateNum)}})</span>

            
          </div>
          <div
            class="price-tips"
            v-if="selectedcoupon"
          >
            使用一张{{
              selectedcoupon.HDJType == 1
                ? `$${selectedcoupon.Maxmoney}`
                : `${selectedcoupon.Percents}折`
            }}优惠券后的费用
          </div>
          <div
            class="price-tips"
            v-if="!selectedcoupon"
          >未使用优惠券</div>
        </div>-->
      </div>
      <!-- the time picker -->
      <van-popup
        v-model="showAppointTime"
        position="bottom"
        :lock-scroll="true"
        :overlay="true"
      >
        <van-picker
          show-toolbar
          title="时区为起点所在时区"
          :columns="timeList"
          @change="changeTime"
          @cancel="cacelAppointment"
          @confirm="confirmTime"
          ref="pickPeople"
        />
      </van-popup>
      <!-- the pre-phonenumber popup selectbox -->
      <van-popup
        v-model="showPhonePre"
        position="bottom"
        :lock-scroll="true"
        :overlay="true"
      >
        <van-picker
          show-toolbar
          :title="title"
          :columns="countryCodeList"
          value-key="TelephoneCode"
          :default-index="defaultTelPreIndex"
          @cancel="onCancel"
          @confirm="onConfirm"
        />
      </van-popup>
      <!-- the coupon pop-->
      <van-popup
        v-model="showCoupon"
        :overlay="true"
        :lock-scroll="true"
        class="coupon-pop"
      >
        <div class="coupon-pop-titleBox">
          <div></div>
          <div class="coupon-pop-tit">选择优惠券</div>
          <div
            class="coupon-pop-cancel"
            @click.stop.prevent="cancelSelect"
          >
            不使用
          </div>
        </div>
        <div :class="[
            'coupon-pop-container',
            {
              'coupon-pop-hasCoupon': couponList.length > 0,
              'coupon-pop-noCoupon': couponList.length == 0,
            },
          ]">
          <div
            class="coupon-list"
            v-if="couponList.length > 0"
          >
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div
                class="coupon-list-item"
                v-for="item in couponList"
                :key="item.ID"
                @click="selectCoupon(item)"
              >
                <raccoupon
                  :item="item"
                  :rateNum="rateNum"
                ></raccoupon>
              </div>
              <div
                class="coupon-tips-addicon"
                @click="gotoCoupon"
              >
                <div class="
                    coupon-tips-addicon-line coupon-tips-addicon-horizontal
                  "></div>
                <div class="coupon-tips-addicon-line coupon-tips-addicon-vertical"></div>
              </div>
            </van-list>
            <!-- <div class="coupon-list-item"
                   v-for="(item,index) in couponList"
                   :key="index">
                <coupon :item="item"></coupon>
            </div>-->
          </div>
          <!-- {{couponList}} -->
          <div v-if="couponList.length == 0">
            <div class="coupon-tips-nocoupon">暂无优惠券</div>
            <div
              class="coupon-tips-addicon"
              @click="gotoCoupon"
            >
              <div class="coupon-tips-addicon-line coupon-tips-addicon-horizontal"></div>
              <div class="coupon-tips-addicon-line coupon-tips-addicon-vertical"></div>
            </div>
          </div>
        </div>
      </van-popup>
      <!-- the tips pop-->
      <van-popup
        v-model="showTips"
        :overlay="true"
        :lock-scroll="true"
        class="tipsBox-pop"
      >
        <div class="tipsBox">
          <div class="tipsTitle">
            <div
              class="tipsCancel"
              @click="cancelTips"
            >取消</div>
            <div class="tipsTit">小费($)</div>
            <div
              class="tipsConfirm"
              @click="coformTips"
            >确定</div>
          </div>
          <div class="tips-input">
            <input
              type="number"
              step="1"
              placeholder="请输入小费额度($)"
              v-model="userTips"
              @blur.prevent="inputLoseFocus"
            />
          </div>
        </div>
      </van-popup>
      <!-- the pop of modify phone -->
      <van-popup
        v-model="showModifyPhone"
        :overlay="true"
        :lock-scroll="true"
        class="tipsBox-pop"
      >
        <div class="tipsBox">
          <div class="tipsTitle">
            <div
              class="tipsCancel"
              @click="cancelTel"
            >取消</div>
            <div class="tipsTit">电话号码</div>
            <div
              class="tipsConfirm"
              @click="conformTel"
            >确定</div>
          </div>
          <div class="tips-input">
            <input
              type="number"
              step="1"
              placeholder="请输入电话号码(纯数字,请勿添加标点符号)"
              v-model="modifyTel"
              @blur.prevent="inputLoseFocus"
            />
          </div>
        </div>
      </van-popup>
    </div>

 
	
	<div class="submit-box">
	  <div
	    :class="[
	      'submitOrder',
	      disabledSubmit ? 'submit-btn-disabled' : 'submit-btn',
	    ]"
	    @click.stop.prevent="submitAnOrder"
	    v-if="readyGo"
	  >
	  <div class="submitPrice">
	    
	   	     <div class="submitDoller">
	   	       <span class="submitCNY">约</span>
	   	       <span
	   	         class="dollar"
	   	         v-if="selectedcoupon !== ''"
	   	       >${{ orderPay }}</span>
	   	       <span
	   	         class="dollar"
	   	         v-if="selectedcoupon == ''"
	   	       >${{ orderPay2 }}</span>
	   	       <span class="submitCNY">(¥{{ selectedcoupon!== ''?Math.floor(orderPay * rateNum):Math.floor(orderPay2 * rateNum)}})</span>
	   	     </div>
	   	   <div
	   	  	       class="submitTips"
	   	  	       v-if="selectedcoupon"
	   	  	     >
	   	  	       使用一张{{
	   	  	         selectedcoupon.HDJType == 1
	   	  	           ? `$${selectedcoupon.Maxmoney}`
	   	  	           : `${selectedcoupon.Percents}折`
	   	  	       }}优惠券后的费用
	   	  	     </div>
	   	  	     <div
	   	  	       class="submitTips"
	   	  	       v-if="!selectedcoupon"
	   	  	     >未使用优惠券</div>
	   	   </div>
	  	 
	  <div class="submitText">
	    立即下单
		</div>
	  </div>
	</div>
	
	
	
    <drawer
      :showDrawer="showDrawer"
      @closeDrawer="closeDrawer"
      ref="drawer"
    ></drawer>
    <!-- </raccoonContainer> -->
    <van-popup
      v-model="showOrder"
      :overlay="true"
      :lock-scroll="true"
      class="order-pop"
    >
      <div class="order-title">进行中的订单</div>
      <div class="order-list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="order-list-item"
            v-for="item in orderItem"
            :key="item.ID"
            @click="
              $router.push(
                `/wait/${item.ID}/${item.OrderType}/${item.IsAssign}/${item.State}`
              )
            "
          >
            <div class="order-type">
              <div :class="[
                  'order-type-title',
                  item.OrderType == '1'
                    ? 'order-type-trip'
                    : item.OrderType == '2'
                    ? 'order-type-designated'
                    : item.OrderType == '3'
                    ? 'order-type-errand'
                    : 'order-type-purchase',
                ]">
                {{
                  item.OrderType == "1"
                    ? "专车"
                    : item.OrderType == "2"
                    ? "代驾"
                    : item.OrderType == "3"
                    ? "取送件"
                    : "代购"
                }}
              </div>
              <div :class="[
                  item.IsAssign == '1'
                    ? 'order-type-inTimeTag'
                    : 'order-type-appointTag',
                ]">
                {{ item.IsAssign == "1" ? "即时订单" : "预约订单" }}
              </div>
              <div class="order-type-status">
                <span
                  v-if="item.State < 6"
                  class="order-type-running"
                >进行中</span>
                <span v-if="item.State == 6">已完成</span>
                <span v-if="item.State > 6">已关闭</span>
                <i :class="[
                    item.State < 6 ? 'order-type-icongreen' : 'order-type-icon',
                  ]"></i>
              </div>
            </div>

            <div class="order-info">
              <div class="order-info-detail">
                <div class="order-info-detail-date">
                  {{ item.NewAppointmentTime }}
                </div>
                <div class="order-info-detail-start">
                  {{ item.StratAddress }}
                </div>
                <div
                  class="order-info-detail-start"
                  v-for="(items, inds) in item.AfterAddress"
                  :key="inds"
                >
                  {{ items.city }}
                </div>
                <div class="order-info-detail-end">{{ item.EndAddress }}</div>
              </div>
              <div class="order-info-sum">
                <div class="order-info-sum-dollar">
                  ${{ item.PayAmount - item.Discount }}
                </div>
                <div class="order-info-sum-cny">
                  (¥{{
                    Math.floor((item.PayAmount - item.Discount) * rateNum)
                  }})
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-popup>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import NavBar from "@/components/navbar/navbar";
import Raccoupon from "@/components/raccoupon/raccoupon";
import Drawer from "@/components/drawer/drawer";
import { Picker, Popup, List, Toast, Dialog } from "vant";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { timePicker } from "../../mixins/timePicker.js";
import { inputBlur } from "@/mixins/inputBlur";
import { showorder } from "@/mixins/showorder";
import { DelArrNull } from "../../lib/units";
import { setStore, getStore, removeStore } from "../../lib/storage";
import {
  getOrderPay,
  submitOrder,
  getGoogleApi,
  getOrderDetailByOrderId,
} from "@/service/";
import wechat from "../../lib/wechat";

export default {
  name: "designated",
  mixins: [timePicker, inputBlur, showorder],
  // props: ['orderItem'],
  components: {
    Drawer,
    // RaccoonContainer,
    // Enthesis,
    NavBar,
    Raccoupon,
    HeaderBar,
    // Tabs,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [List.name]: List,
    [Toast.name]: Toast,
  },
  data() {
    return {
      nearBy: 0,
      trip: true,
      isWX: false,
      navItems: [
        {
          name: "出行",
          path: "trip",
          // components: Trip,
          id: 0,
        },
        {
          name: "代驾",
          path: "designated",
          // components: Designated,
          id: 1,
        },
        {
          name: "跑腿",
          path: "errand",
          // components: Errand,
          id: 2,
        },
        // {
        //   name: '搬家',
        //   path: 'movehouse',
        //   // components: Errand,
        //   id: 3
        // },
        // {
        //   name: '专车',
        //   path: 'specialcar',
        //   // components: Errand,
        //   id: 4
        // },
      ],
      items: ["现在出发", "预约代驾"],
      itemComponents: [
        {
          name: "现在出发",
          path: "",
          // components: GoNow,
          id: 0,
        },
        {
          name: "预约代驾",
          path: "",
          // components: Appointment,
          id: 1,
        },
      ],
      // 导航条激活索引
      activeId: 1,
      // 预约和即时单索引
      // activeItemId: 0,
      // 显示预约时间框
      showAppointTime: false,
      // 显示电话前缀弹框
      showPhonePre: false,
      // showCarListPop: false,
      showModifyPhone: false,
      // 修改号码
      modifyTel: "",
      // 显示优惠券框
      showCoupon: false,
      // 优惠券列表
      // couponList: [],
      // 优惠券费用值
      couponNum: 0,
      // 显示小费框
      showTips: false,
      title: "选择国家",
      // 小费输入框
      userTips: "",
      // 小费值
      tipNum: 0,
      // 预约时间
      appointmentTime: "",
      // 初始化电话前缀
      telPre: "+1",
      defaultTelPreIndex: 0,
      // 选中的优惠券
      selectedcoupon: "",
      // 初始化费用
      // orderPay: 0,
      // 费用备份
      price: 0,
      // 费用备份
      preprice: 0,
      // 距离
      distance: 0,
      PayGuid: "",
      startPlaceholder: "定位中...",
      transferPlaceholder: "请输入您的经停点",
      endPlaceholder: "请输入您的终点",
      transfer: "",
      loading: false,
      finished: false,
      timer: null,
      // 提交按钮禁用
      disabledSubmit: false,
      showDrawer: false,
      couponMoney: "",

      // showOrder:false
    };
  },
  computed: {
    ...mapState({
      tips: ({ globalVal }) => globalVal.tips,
      readyGo: ({ globalVal }) => globalVal.readyGo,
      countryCodeList: ({ globalVal }) =>
        globalVal.countryCodeList || JSON.parse(getStore("countryCodeList")),
      transferLocation: ({ pathVal }) => pathVal.transferLocation,
      geocodeAddress: ({ globalVal }) => globalVal.geocodeAddress,
      // tripStartPlace: ({ globalVal }) => globalVal.tripStartPlace,
      // tripEndPlace: ({ globalVal }) => globalVal.tripEndPlace,
      startAddress: ({ globalVal }) =>
        globalVal.startAddress || getStore("startAddress"),
      startGeo: ({ globalVal }) =>
        globalVal.startGeo || JSON.parse(getStore("startGeo")),
      endAddress: ({ globalVal }) =>
        globalVal.endAddress || getStore("endAddress"),
      endGeo: ({ globalVal }) =>
        globalVal.endGeo || JSON.parse(getStore("endGeo")),
      carList: ({ globalVal }) => globalVal.carList,
      // peopleNum: ({ globalVal }) => globalVal.peopleNum,

      activeItemId: ({ globalVal }) => globalVal.activeItemId,
      rateNum: ({ globalVal }) => globalVal.rateNum,
      selectedCar: ({ globalVal }) => globalVal.selectedCar,
      typeList: ({ globalVal }) => globalVal.typeList,
      orderItem: ({ userVal }) => userVal.orderItem,
      userCouponList: ({ userVal }) => userVal.userCouponList,
    }),
    ...mapGetters(["tripCarList", "defaultSelectedCar"]),
    userInfo() {
      return JSON.parse(getStore("userInfo"));
    },
    telPre() {
      console.log("countryCodeList:", this.countryCodeList);
      //  console.log('this.countryCodeList[this.defaultTelPreIndex].TelephoneCode:',this.countryCodeList[this.defaultTelPreIndex].TelephoneCode)
      // return this.countryCodeList[this.defaultTelPreIndex].TelephoneCode;
      return localStorage.getItem("TelephoneCodepre")
        ? localStorage.getItem("TelephoneCodepre")
        : this.countryCodeList[this.defaultTelPreIndex].TelephoneCode;
      // set () { }
    },
    wxinfo() {
      return getStore("wx");
    },
    imgUrl() {
      return require(`../../assets/logo.png`);
    },
    position() {
      return JSON.parse(getStore("pos"));
    },
    isAssign() {
      return this.activeItemId + 1;
    },
    defaultCar: {
      get() {
        return this.selectedCar || this.defaultSelectedCar;
      },
      set() {},
    },
    vehicleUserTel: {
      get() {
        return (
          this.modifyTel ||
          window.sessionStorage.getItem("modifyTel") ||
          (JSON.parse(getStore("userInfo")) &&
            JSON.parse(getStore("userInfo")).Tel)
        );
      },
      set() {},
    },
    orderPay() {
      if (this.isAssign == "1") {
        return this.price - this.couponNum + this.tipNum || 0;
      } else {
        return this.preprice - this.couponNum + this.tipNum || 0;
      }
    },
    orderPay2() {
      if (this.isAssign == "1") {
        return this.price + this.tipNum || 0;
      } else {
        return this.preprice + this.tipNum || 0;
      }
    },
    couponList: {
      get() {
        return this.userCouponList.filter((item) => {
          return item.Effective == 1;
        });
      },
      set() {},
    },
  },
  watch: {
    startAddress() {
      this.SELECT_CAR("");
      let latlng = JSON.stringify(this.startGeo);
      let type = this.typeList[1].typenumber;
      if (this.endGeo && this.startGeo) {
        this.calcOrderPay();
      }
      this.getCarListAround([latlng, type]);
    },
    endAddress() {
      if (this.startAddress == "") return;
      // console.log(`watch终点信息 调用计费信息`)
      this.calcOrderPay();
    },
    // orderItem() {
    //   console.log("orderItemorderItem");
    //   this.imlogin();
    //   if (this.orderItem && this.orderItem.length > 0) {
    //     this.imlogin();
    //   } else {
    //     this.$chat.loginout();
    //   }
    // },
  },
  methods: {
    ...mapMutations([
      "SELECT_CAR",
      "GET_USER_LOCATION",
      "GET_CARLIST_AROUND",
      "SET_PEOPLE_NUM",
      "SET_ACTIVEITEMID_NUM",
      "SELECT_CAR",
      "DEL_TRANSFER_LOCATION",
      "CLEAR_STARTADDRESS",
    ]),
    ...mapActions([
      "getCountry",
      "geolocation",
      "geocodeLatLng",
      "getCarListAround",
      "exchangeRateHandler",
      "getCoupon",
      "queryUserAndOrder",
      "getgoogleMapPosition",
    ]),
    // ...mapGetters(['couponCanUseList']),
    // goToOrder () {
    //   this.$router.push('/order')
    // },
    imlogin() {
      if (this.$chat.isLogin()) {
        console.log("已登录");
        // this.register();
      } else {
        console.log("未登录");
        this.login();
      }
    },
    // 登录
    login() {
      let _userInfo = JSON.parse(getStore("userInfo"));
      let form = {
        username: "UM" + _userInfo.Tel,
        password: "123456",
      };
      this.$chat
        .login(form)
        .then((res) => {
          console.log("登录成功", res);
          if (this.content) {
            this.sendSingleMsg();
          }
        })
        .catch((err) => {
          console.log("登录失败 err:", err);
          if (this.registerNum < 4) {
            this.register();
          }
        });
    },
    closeDrawer() {
      this.showDrawer = false;
    },
    // 获取订单信息
    async getOrderDetail() {
      console.log("getOrderDetail_orderid", getStore("orderId"));
      if (getStore("orderId")) {
        let res = await getOrderDetailByOrderId(getStore("orderId"));
        if (res.data.State > 5) {
          if (getStore("msglist")) removeStore("msglist");
          if (getStore("orderId")) removeStore("orderId");
          if (getStore("msgnums")) removeStore("msgnums");
          if (getStore("im_Pmid")) removeStore("im_Pmid");
          if (getStore("im_Umid")) removeStore("im_Umid");
          removeStore("orderId");
        }
        console.log("trip_getOrderDetail_res:", res);
      } else {
        console.log("no orderid");
      }
    },
    popUserInfo() {
      let userInfo = JSON.parse(getStore("userInfo"));
      // console.log(this.showLogin)
      if (userInfo && userInfo.ID) {
        this.showDrawer = true;
      } else {
        this.showLogin = true;
      }
    },
    // tab切换
    tab(index) {
      // console.log('parent' + index)
      // this.activeItemId = index;
      this.SET_ACTIVEITEMID_NUM(index);
      this.calcOrderPay();
      // console.log(this.activeItemId)
    },
    // tab切换
    tabNav(index) {
      console.log("parent" + index);
      this.activeId = index;
      this.$router.push(`/${this.navItems[index].path}`);
      console.log(this.activeItemId);
    },
    // 添加中间位置
    addTransferLocation() {
      if (this.transferLocation.length < 5) {
        this.transferLocation.push("");
      }
    },
    // 删除中间位置
    deleteItem(index) {
      let iscalc = false;
      if (this.transferLocation[index].city) {
        iscalc = true;
      }
      this.DEL_TRANSFER_LOCATION(index, 1);
      if (this.endGeo && this.startGeo && iscalc) {
        this.calcOrderPay();
      }
    },
    // 电话号码选择确认--前缀
    onConfirm(value, index) {
      // console.log(value)
      // console.log(index)
      // this.defaultTelPreIndex = index;
      localStorage.setItem("TelephoneCodepre", value.TelephoneCode);
      this.telPre = value.TelephoneCode;
      this.showPhonePre = false;
    },
    // 电话号码选择取消--前缀
    onCancel() {
      this.showPhonePre = false;
    },
    // 取消选择预约时间
    cacelAppointment() {
      this.showAppointTime = false;
    },
    // 切换预约时间
    changeTime(picker, values) {
      picker.setColumnValues(1, this.timelist[values[0]]);
    },
    // 确定预约时间
    confirmTime(value) {
      // console.log(value)
      let date = `${value[0]}`;
      let time = `${value[1]}:${value[2]}`;
      this.appointmentTime = `${date} ${time}`;
      this.calcOrderPay();
      // console.log(this.appointmentTime)
      this.showAppointTime = false;
    },
    // 显示预约时间框
    popAppointTime() {
      this.showAppointTime = true;
      // Toast('离预约时间小于30分钟无人接单，订单将被自动取消！')
    },
    // 显示电话前缀
    popupPhonePre() {
      this.showPhonePre = true;
    },
    // 修改手机号码
    popModifyPhone() {
      this.showModifyPhone = true;
    },
    // 显示优惠券列表
    popCouponList() {
      if (this.end == "") {
        Toast("请输入终点");
        return false;
      }
      this.getCanUseCoupon(this.userInfo.ID);
      this.showCoupon = true;
    },
    // 取消优惠券列表
    cancelSelect() {
      this.showCoupon = false;
      this.selectedcoupon = "";
      this.getCanUseCoupon();
    },
    // 选中优惠券
    selectCoupon(coupon) {
      this.couponMoney = coupon.Orderprice;
      console.log(this.couponMoney);
      if (
        this.couponMoney < this.orderPay ||
        this.couponMoney <= this.orderPay2
      ) {
        this.selectedcoupon = coupon;
        this.couponNum = parseFloat(coupon.Maxmoney);
        // let orderpay = this.price - coupon.Maxmoney
        // this.orderPay = orderpay
        // console.log(`选优惠券`)
        // console.log(`${this.selectedcoupon}`)
      } else {
        Toast("满" + this.couponMoney + "使用");
      }
      this.showCoupon = false;
    },
    // 显示小费输入框
    popTips() {
      this.showTips = true;
    },
    // 取消输入小费
    cancelTips() {
      this.showTips = false;
    },
    // 取消输入手机号码
    cancelTel() {
      this.showModifyPhone = false;
      //  this.modifyTel =
      //   JSON.parse(getStore("userInfo")) &&
      //   JSON.parse(getStore("userInfo")).Tel;
      // window.sessionStorage.setItem("modifyTel", this.modifyTel);
    },

    // 确定小费
    coformTips() {
      // let orderpay = this.price
      // this.orderPay = orderpay
      if (!/^\+?[0-9][0-9]*$/.test(this.userTips)) {
        Toast("小费金额应为大于0的整数！");
        return;
      }
      if (this.userTips == "") {
        this.tipNum = 0;
      } else {
        this.tipNum = parseFloat(this.userTips);
      }

      this.showTips = false;
    },
    // 确定电话
    conformTel() {
      // this.vehicleUserTel =
      window.sessionStorage.setItem("modifyTel", this.modifyTel);
      this.showModifyPhone = false;
    },
    // onload
    onLoad() {
      this.loading = false;

      this.finished = true;
    },
    // 获取大约价格
    async calcOrderPay() {
      this.disabledSubmit = true;
      let orderType = "2";
      let dccode = this.isAssign == "1" ? "100102" : "100105";
      //   let appointmentTime =
      //     this.isAssign == "1"
      //       ? this.formateDate(new Date())
      //       : this.appointmentTime;
      let appointmentTime =
        this.isAssign == "1"
          ? ""
          : this.appointmentTime
          ? this.appointmentTime
          : this.formateDate(new Date());
      let res,
        parmsArr = [],
        start_geo = {},
        end_geo = {};
      // start_geo = { city: JSON.parse(localStorage.getItem("startGeo")) };
      start_geo = { city: this.startGeo };
      end_geo = { city: JSON.parse(sessionStorage.getItem("endGeo")) };
      console.log("end_geo:", end_geo);
      if (!end_geo.city || !start_geo.city) return;
      parmsArr.push(start_geo);
      if (this.transferLocation.length) {
        let _transfer = [];
        for (let i in this.transferLocation) {
          if (this.transferLocation[i].city) {
            let obj = {
              city: this.transferLocation[i].geo,
            };
            _transfer.push(obj);
          }
        }
        parmsArr = parmsArr.concat(_transfer);
      }
      parmsArr.push(end_geo);
      let url = JSON.stringify(parmsArr);
      res = await getGoogleApi(orderType, url, appointmentTime, this.isAssign);
      if (res) {
        this.price = res.data;
        this.preprice = res.data;
        this.distance = res.Mileage;
        this.PayGuid = res.PayGuid;
        setTimeout(() => {
          this.disabledSubmit = false;
        }, 1500);
      }
    },
    gotoCoupon() {
      this.$router.push(`/exchangecoupon`);
    },
    // 获取优惠券
    async getCanUseCoupon() {
      this.couponList = this.userCouponList.filter((item) => {
        return item.Effective == 1;
      });

      // console.log(this.couponList)
    },

    // 提交订单
    async submitAnOrder() {
      console.log(this.selectedCar);
      console.log("defaultSelectedCar:", this.defaultSelectedCar);

      // this.selectedCar || this.defaultSelectedCar
      if (this.startAddress == "") {
        Toast("请输入起点");
        return;
      }
      if (this.startGeo == "") {
        Toast("无效起点，请重新选择起点");
        return;
      }
      if (this.endAddress == "") {
        Toast("请输入终点");
        return;
      }
      if (this.endGeo == "") {
        Toast("无效终点，请重新选择终点");
        return;
      }

      let Umid = JSON.parse(getStore("userInfo")).ID;
      let formdata;
      let AppType = 3;

      if (this.isWX) {
        AppType = 2;
      }
      console.log("isAssign:", this.isAssign);
      console.log(this.defaultCar);

      if (this.isAssign == "1") {
        if (!this.defaultCar) {
          Toast("暂无司机，请预约下单!");
          return;
        }
        // if (this.peopleNum > this.defaultCar.MannedNumber) {
        //   Toast('车辆载人数量不足！')
        //   return
        // }
        formdata = {
          AppType: AppType,
          IsAssign: this.isAssign,
          OrderType: 2,
          Adid: this.selectedcoupon.ID,
          Umid: Umid,
          DcCode: "100102",
          Pmid: this.defaultCar.Pmid,
          Vmid: this.defaultCar.Vmid,
          IsSubstitution: this.vehicleUserTel == this.userInfo.Tel ? "0" : "1",
          VehicleUserTel: this.telPre + this.vehicleUserTel,
          StratAddress: this.startAddress,
          StratGeo: JSON.stringify(this.startGeo),
          EndAddress: this.endAddress,
          EndGeo: JSON.stringify(this.endGeo),
          NewAppointmentTime: this.formateDate(new Date()),
          Mileage: this.distance,
          PayGuid: this.PayGuid,
          AfterAddress: JSON.stringify(DelArrNull(this.transferLocation)),
        };
      } else {
        if (!this.appointmentTime) {
          Toast("请选择预约时间！");
          return;
        }
        formdata = {
          AppType: AppType,
          IsAssign: this.isAssign, //及时单还是预约单
          OrderType: 2, //订单类型 2代表代驾  1=及时,2=代驾3=取送件4=代购
          Gratuity: this.userTips || 0, //小费
          Adid: this.selectedcoupon.ID, //优惠券id
          Umid: Umid, //用户id
          DcCode: "100105",
          IsSubstitution: this.vehicleUserTel == this.userInfo.Tel ? "0" : "1", //是否帮别人叫
          VehicleUserTel: this.telPre + this.vehicleUserTel, //号码
          StratAddress: this.startAddress,
          StratGeo: JSON.stringify(this.startGeo),
          EndAddress: this.endAddress,
          EndGeo: JSON.stringify(this.endGeo),
          AppointmentTime: this.appointmentTime || new Date(),
          Mileage: this.distance,
          PayGuid: this.PayGuid,
          AfterAddress: JSON.stringify(DelArrNull(this.transferLocation)),
        };
      }
      this.disabledSubmit = true;
      // loading start
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: "loading...",
      });
      console.log(formdata);
      try {
        let res = await submitOrder(formdata);
        setTimeout(() => {
          this.disabledSubmit = false;
        }, 1000);
        if (res.code == 1) {
          // loading close
          Toast.clear();
          let orderId = res.data;
          setStore("orderId", res.data);
          if (this.isAssign == 1) {
            this.$router.push(`/wait/${orderId}/2/${this.isAssign}/2`);
          } else {
            this.$router.push(`/wait/${orderId}/2/${this.isAssign}/1`);
          }
        } else if (res.code == 102) {
          Toast.clear();
          Toast(res.msg);
        } else {
          Toast.clear();
          Toast(res.msg);
        }
      } catch (error) {
        setTimeout(() => {
          this.disabledSubmit = false;
        }, 1000);
        console.log(error);
      }
    },

    // 选择地址跳转路由
    selectAddress(type, address, index) {
      if (address == 3) {
        this.$router.push({
          path: `/searchAddress/${type}/${address}`,
          query: {
            indexWhich: index,
          },
        });
      } else {
        this.$router.push(`/searchAddress/${type}/${address}`);
      }
    },
    // google locate timer handler
    // 3s locate user's network address
    geoTimeHandler() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.geolocation();
      }, 3000);
    },

    //获取当前定位  Key和referer修改为自己项目的值
    getPosition() {
      //   this.showPosition函数表示定位成功的函数;
      //   this.errorPosition函数表示定位失败的函数;
      const _Key = "XCLBZ-6HWKV-PKZPZ-UT3EL-KZWHV-VQBUG";
      var geolocation = new window.qq.maps.Geolocation(_Key, "dome");
      geolocation.getLocation(this.showPosition, this.errorPosition);
    },
    async showPosition(position) {
      console.log("showPosition:", position);
      var latlng = {
        lat: position.lat,
        lng: position.lng,
      };
      console.log("home_latlng:", latlng);
      this.GET_USER_LOCATION(latlng);
      setStore("pos", latlng);
      setStore("startAddress", "");
      await this.CLEAR_STARTADDRESS();
      //   this.geocodeLatLng();
    },
    //定位失败
    errorPosition() {
      console.log("定位失败");
      //继续定位
      //   this.getPosition();
    },
  },
  created() {
    console.log("designated_created");
    this.getOrderDetail();
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //微信浏览器
      this.isWX = true;
    }
    // this.geolocation().then(() => {
    //   this.geocodeLatLng();
    // });
    // 极光IM连接
    // this.$chat.getJim();
    // this.init();
    // if (!this.rateNum) {
    //   this.exchangeRateHandler()
    // }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  async mounted() {
    removeStore("annidx");
    // this.exchangeRateHandler()
    // this.geolocation().then(()=>{
    let userGeo = await JSON.parse(getStore("pos"));
    console.log("designated_mounted_userGeo：", userGeo);
    // if (
    //   userGeo == undefined ||
    //   userGeo == null ||
    //   this.startAddress == "" ||
    //   this.startAddress == null
    // ) {
    //   //   this.geocodeLatLng();
    //   this.geolocation().then(() => {
    //     this.geocodeLatLng();
    //   });
    //   this.geolocation();
    // }

    // let preindex = this.countryCodeList.findIndex(item => {
    //   // return item.TelephoneCode == this.userInfo.AreaCode;
    //   return item.TelephoneCode == this.userInfo.AreaCode?this.userInfo.AreaCode:this.userInfo.Tel;
    // });
    // this.defaultTelPreIndex = preindex;
    // })
    this.defaultTelPreIndex = localStorage.getItem("TelephoneCodepre");
    let userInfo = JSON.parse(getStore("userInfo"));
    if (userInfo && userInfo.ID) {
      this.queryUserAndOrder(userInfo.ID);
      this.showLogin = false;
    } else {
      this.showLogin = true;
    }
    // let latlng = {
    //   usergeo: JSON.stringify(this.startGeo)
    // }
    let latlng = JSON.stringify(this.startGeo);
    if (!latlng) return;
    let type = this.typeList[1].typenumber;
    this.getCarListAround([latlng, type]);
    // this.googlelocate()
    this.getTimeList(1, 9);
    this.getCoupon([this.userInfo.ID, "2"]);
    this.calcOrderPay();
    if (!this.rateNum) {
      this.exchangeRateHandler();
    }
    if (localStorage.getItem("TelephoneCodepre")) {
      this.telPre = localStorage.getItem("TelephoneCodepre");
    }
    console.log("telPre:", this.telPre);
    // this.$refs.drawer.createQRcode()
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";
@import "../../styles/orderpop.less";
@import "../../styles/form.less";
.posticon {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 50px;
}
.nearBy {
  margin: 20px auto;
  .fontSize(24px);
  line-height: 34px;
  .text-center;
  .color(@gray);
}
.tripLine {
  margin-bottom: 10px;
}

.hasOrder {
  width: 300px;
  height: 60px;
  margin: 20px auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  text-align: center;
  line-height: 60px;
  font-size: 24px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: rgba(12, 170, 50, 1);

  .order-icon {
    display: inline-block;
    margin-left: 10px;
    .size(10px, 20px);
    background: url("../../assets/back_green.png") 0 0 no-repeat;
    background-size: 10px 20px;
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
  opacity: 1;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
  opacity: 1;
}

.divide-line {
  width: 580px;
  margin-left: 74px;
  height: 2px;
  background: rgba(238, 238, 238, 1);
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}

.startoff-box {
 // .size(690px, 540px);
  .size(690px, auto);
}
.startoff-appointment {
  .size(690px, auto);
}

.appointment-time {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 100px;
  text-align: center;

  .clock {
    display: inline-block;
    .size(32px, 32px);
    margin-right: 10px;
    background: url("../../assets/clock@2x.png") 0 0 no-repeat;
    background-size: 32px 32px;
  }
}

.box-line {
	width:  100%;
	height: 2px;
	background: #eee;
}
.startoff {
  margin: 0 auto;
  margin-bottom: 220px;
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 20px;

  .tabs {
    width: 86%;
    display: flex;
    flex: 1;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    .items {
      display: flex;
      flex: 1;
      height: 90px;
      align-items: center;
      .fontSize(30px);
      justify-content: space-around;
     // border-bottom: 2px solid #eee;

      .item-list {
        width: 50%;
        position: relative;
        left: 8%;
        box-sizing: border-box;
        height: 100%;
        font-size: 30px;
        font-family: "PingFangSC-Medium";
        line-height: 90px;
        text-align: center;
      }
    }
  }

  .items-list {
    display: flex;
    flex: 1;
    height: 80px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    border-bottom: 2px solid #eee;
    .fontSize(30px);
    line-height: 42px;

    .items-title {
      .color(#999);
    }

    .remark {
      width: 390px;
      height: 42px;
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      line-height: 42px;
      input {
        .size(100%, 100%);
        .color(#333);
      }
    }
  }
  .siji {
    height: 110px;
  }
  .right-arrow {
    .size(12px, 20px);
    background: url("../../assets/back@2x.png") right center no-repeat;
    background-size: 12px 20px;
    margin-left: 8px;
  }
  .passenger-num {
    margin-left: auto;
    .color(#333);
    input {
      .text-right;
    }
  }
  .select-coupon {
    margin-left: auto;
    .color(#ccc);
  }
  .selected-coupon {
    margin-left: auto;
    .color(#333);
  }

  .car-type {
    //margin-left: 350px;
    margin-right: 8px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
	 
  }
  .carIcon {
    display: flex;
    .size(70px, 70px);
    margin-right: 10px;
    border-radius: 50%;
    .bg(#f3f3f3);
    align-items: center;
    justify-content: center;
    img {
      width: 45px;
      margin: 0 auto;
      vertical-align: middle;
    }
	
	 
  }
  .car-name {
   .fontSize(28px);
   line-height: 34px;
   
   .car-driver-name {
     display: inline-block;
     margin-right: 10px;
   }
   .car-driver-cartype {
     .fontSize(20px);
     .color(#666);
   }
  }

  .car-des {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .car-star {
    margin-right: 20px;
    .fontSize(20px);
    .color(#f5a623);
    line-height: 34px;
  }
  .car-star-icon {
    display: inline-block;
    .size(16px, 16px);
    margin-right: 6px;
    background: url("../../assets/star-small@2x.png") 0 center no-repeat;
    background-size: 16px 16px;
  }
  .car-seat {
	  margin-right: 20px;
    .fontSize(24px);
    .color(#999);
  }
  
  .car-distance {
    .fontSize(24px);
    .color(#999);
  }

  .car-seat-icon {
    display: inline-block;
    .size(22px, 22px);
    margin-right: 8px;
    background: url("../../assets/people@2x.png") 0 center no-repeat;
    background-size: 22px 22px;
  }
  .preNum {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 40px;
    margin-right: auto;
    .color(#999);
  }
  .selectCountry {
    display: inline-block;
    margin-left: 10px;
    .size(20px, 10px);
    background: url("../../assets/drag@2x.png") 0 0 no-repeat;
    background-size: 20px 10px;
  }
  .sum {
    // padding: 20px 0 30px;
    .text-center;
    .price {
      font-family: "PingFangSC-Semibold";
      font-size: 40px;
      color: #0caa32;
      line-height: 56px;
      .cny {
        font-family: "PingFangSC-Regular";
        font-size: 24px;
        color: #999;
      }
    }
    .price-tips {
      font-family: "PingFangSC-Regular";
      font-size: 24px;
      color: #999999;
    }
  }

  .active {
    line-height: 90px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: @brand-green;
    border-bottom: 4px solid @brand-green;
  }

  .no-car {
    display: flex;
    align-items: center;
    margin-left: auto;
    text-align: right !important;
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(255, 79, 82, 1);
    line-height: 34px;
  }

  .tipsBox {
    width: 690px;
    height: 330px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 0 30px;
    box-sizing: border-box;

    &-pop {
      border-radius: 20px;
    }

    .tipsTitle {
      display: flex;
      flex: 1;
      margin: 60px 0;
      align-items: center;
      justify-content: space-between;
    }

    .tipsCancel {
      font-size: 36px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 50px;
    }
    .tipsTit {
      font-size: 36px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
      line-height: 50px;
    }
    .tipsConfirm {
      font-size: 36px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 50px;
    }

    .tips-input {
      width: 630px;
      height: 80px;
      background: rgba(238, 238, 238, 1);
      border-radius: 8px;

      input {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: none;
        text-align: center;
        background: rgba(238, 238, 238, 1);
        font-size: 30px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        // color: rgba(204, 204, 204, 1);
        line-height: 42px;
      }
    }
  }

  .coupon {
    &-pop {
      width: 690px;
      // height: 760px;
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      touch-action: none;

      &-titleBox {
        display: flex;
        flex: 1;
        height: 120px;
        padding: 0 30px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid rgba(238, 238, 238, 1);
      }
      &-container {
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        border-radius: 20px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }

      &-hasCoupon {
        width: 690px;
        height: 640px;
      }

      &-noCoupon {
        width: 690px;
        height: 388px;
      }

      &-tit {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
        line-height: 50px;
      }

      &-cancel {
        font-size: 36px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 50px;
      }
    }

    &-list {
      box-sizing: border-box;
      // overflow: hidden;
      // touch-action: none;
      &-item {
        margin: 30px auto;
      }
    }

    &-tips {
      &-nocoupon {
        margin: 30px auto;
        font-size: 30px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 42px;
        text-align: center;
      }

      &-addicon {
        width: 160px;
        height: 160px;
        margin: 0 auto;
        border-radius: 50%;
        border: 6px solid rgba(204, 204, 204, 1);
        position: relative;
        box-sizing: border-box;

        &-line {
          position: absolute;
          // width: 60px;
          // height: 6px;
          background: rgba(216, 216, 216, 1);
        }

        &-horizontal {
          width: 60px;
          height: 6px;
          left: 47px;
          right: 47px;
          top: 71px;
          bottom: 71px;
        }

        &-vertical {
          width: 6px;
          height: 60px;
          top: 47px;
          bottom: 47px;
          left: 71px;
          right: 71px;
          // transform: rotate(90deg);
          transform-origin: 0 0;
        }
      }
    }
  }
}
.raccoon-err {
  display: inline-block;
  margin-right: 10px;
  .size(24px, 24px);
  background: url("../../assets/exclamation.png") 0 0 no-repeat;
  background-size: 24px 24px;
  // border-radius: 50%;
}
.pop-section {
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}

.van-popup {
  &--right {
    width: 100vw;
    height: 100vh;
  }
}

.submit-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  align-items: center;
  // widows: 100%;
  height: 160px;
  // filter: blur(10px);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    #ffffff 100%
  );
}



.submitOrder {
  .size(690px, 90px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
 // .text-center;
 
}
 
 

.submitPrice{
	 
	padding-left: 30px;
	.color(@white);
	line-height: 210px;
	.fontSize(30px);
	font-family: "PingFangSC-Medium";
	color: rgba(255, 255, 255, 1);
	 display: grid;
	  grid-template-columns: 1fr;
}

.submitDoller{
	
	   font-family: "PingFangSC-Semibold";
	      font-size: 38px;
	      color: rgba(255, 255, 255, 1);
	      line-height: 22px;
		  padding-top: 18px;
		  
}

.submitCNY{
	 font-family: "PingFangSC-Regular";
	        font-size: 26px;
	        color: rgba(255, 255, 255, 1);
}

.submitTips{
	     
	 font-family: "PingFangSC-Regular";
	      font-size: 20px;
	      color: rgba(255, 255, 255, 1);
		   line-height: 0px;
}

.submitText{
	
	padding-right: 30px;
	.color(@white);
	line-height: 90px;
	.fontSize(30px);
	font-family: "PingFangSC-Medium";
	font-weight: 500;
	color: rgba(255, 255, 255, 1);
}
 

.submit-btn {
  background: rgba(12, 170, 50, 1);
  &-disabled {
    // .bg(rgba(1, 150, 37, 1));
    background-color: #999;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>